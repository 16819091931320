//*******************************************************************************
// LISTS
//*******************************************************************************

.list {
	margin-top: 0;
	margin-left: 0;
	padding-left: 0;
	margin-bottom: 6vw;

	@include breakpoint(min) {
		margin-bottom: 24px;
	}

	@include breakpoint(mid) {
		margin-bottom: 2vw;
	}

	ul,
	ol {
		margin-top: 0;
		padding-left: 0;
	}

	li {
		margin-bottom: 0;
	}
}



//
// Ordered List
//
.list--ordered {
	margin-top: 0;
	margin-left: 20px;

	ol {
		padding-left: 20px;
	}
}



//
// List without Numbers/Bullets
//
.list--unstyled {
	ul {
		margin-top: 0;
	}

	li {
		list-style: none;
		padding-left: 0;
		margin-bottom: 0;

		&::before {
			display: none;
		}
	}
}



//
// List Inline
//
.list--inline {
	padding-left: 0;
	list-style: none;

	li {
		display: inline-block;

		&:not(:last-child) {
			margin-right: 5px;
		}
	}
}



//
// List with Circle Item
//
.list--circle,
.textmedia__text ul,
.textcontent ul {
	margin-top: 0;
	margin-left: 0;
	padding-left: 0;

	li {
		position: relative;
		list-style-type: none;
		margin-bottom: 0;
		padding-left: 20px;

		&::before {
			content: '';
			display: block;
			position: absolute;
			top: calc(7px + 8*(100vw - 400px)/1200);
			left: 0;
			width: 6px;
			height: 6px;
			background-color: color(red500);
			border-radius: 3px;

			@include breakpoint($min: 800px) {
				top: calc(4px + 8*(100vw - 400px)/1200);
			}
		}
	}
}



//
// List with Square Item
//
.list--square {
	margin-top: 0;
	margin-left: 0;
	padding-left: 0;

	li {
		position: relative;
		list-style-type: none;
		margin-bottom: 0;
		padding-left: 20px;

		@include breakpoint(mid) {
			padding-left: 30px;
		}

		&::before {
			content: '';
			display: block;
			position: absolute;
			top: calc(4px + 8 * (100vw - 400px) / 1200);
			left: 0;
			width: 12px;
			height: 12px;
			background-color: color(red500);
			border-radius: 2px;

			@include breakpoint(mid) {
				width: 16px;
				height: 16px;
				top: calc(0 + 8 * (100vw - 400px) / 1200);
			}
		}
	}
}


//
// List with Arrow Item
//
.list--arrow {
	li {
		position: relative;
		list-style-type: none;
		margin-bottom: 0;
		padding-left: 0;
	}

	.linkappearance,
	a {
		display: inline-block;
		height: 100%;
		color: color(red500);

		&:active,
		&:focus,
		&:hover {
			text-decoration: none;
			color: color(red600);
		}

		&::after {
			content: '';
			display: inline-block;
			width: 24px;
			height: 12px;
			background-size: 6px;
			background-position: left 8px top 2px;
			background-repeat: no-repeat;
			background-color: transparent;
			@include background-svg('<svg width="6" height="10" viewBox="0 0 6 10" xmlns="http://www.w3.org/2000/svg"><path d="M1 1l4 4-4 4" stroke="#EC0016" stroke-width="1.333" fill="none" fill-rule="evenodd" stroke-linecap="round" stroke-linejoin="round"/></svg>');
			transition: all $transition-time $transition-function;

			@include breakpoint(mid) {
				height: 14px;
				background-size: 7px;
			}

			@include breakpoint(max) {
				height: 15px;
				background-size: 8px;
			}
		}

		&:active::after,
		&:focus::after,
		&:hover::after {
			@include background-svg('<svg width="6" height="10" viewBox="0 0 6 10" xmlns="http://www.w3.org/2000/svg"><path d="M1 1l4 4-4 4" stroke="#C50014" stroke-width="1.333" fill="none" fill-rule="evenodd" stroke-linecap="round" stroke-linejoin="round"/></svg>');
			background-position: left 12px top 2px;
		}
	}
}

.textcontent ul.list--arrow li {
	padding-left: 0;

	&::before {
		display: none;
	}
}
