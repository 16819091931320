//*******************************************************************************
// News-Teasers Component
//*******************************************************************************

html.msie {
	.newsteasers__list > * {
		@include breakpoint(mid) {
			flex: 0 0 44%;
		}

		@include breakpoint($min: 1200px) {
			flex: 0 0 27%;
		}
	}
}

.newsteasers {
	padding-bottom: 10vw;

	@include breakpoint(min) {
		padding-bottom: 5vw;
	}

	@include breakpoint(mid) {
		padding-bottom: 0;
	}

	&__list {
		margin-top: 5vw;

		@include breakpoint(mid) {
			display: flex;
			flex-wrap: wrap;
			justify-content: flex-start;
			// display: grid;
			// grid-template-columns: repeat(18,1fr);
			// grid-template-rows: auto auto;
			// margin-top: 5vw;
		}

		@include breakpoint(max) {
			margin-top: 2.5vw;
		}

		&--limit > :nth-child(n + 4) {
			display: none;
		}

		@include breakpoint(mid) {
			&--limit > :nth-child(3) {
				display: none;
			}
		}

		> * {
			padding: 7.5vw;

			@include breakpoint(mid) {
				// grid-column-end: span 9;
				flex: 0 0 50%;
				padding: 2.5vw 2.5vw 5vw;
			}

			@include breakpoint($min: 1200px) {
				// grid-column-end: span 6;
				flex: 0 0 33%;
				padding: 2.5vw 2.5vw;

				&:nth-child(3) {
					display: block;
				}
			}
		}
		> .teaser {
			margin: 0;
			text-align: center;
		}
	}

	&__all-wrapper {
		text-align: center;
	}

	.teaser__title {
		font-size: 5vw;
		line-height: 6vw;

		@include breakpoint(min) {
			font-size: 20px;
			line-height: 26px;
		}

		@include breakpoint(mid) {
			line-height: 32px;
		}

		@include breakpoint(max) {
			font-size: 1.25vw;
			line-height: 2vw;
		}
	}
}
