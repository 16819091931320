//*******************************************************************************
// BUTTONS
//*******************************************************************************

//
// Button Primary
//
.btn {
	@include font-family(headlight);
	position: relative;
	display: inline-block;
	padding: 7px 8px;
	line-height: 1;
	color: color(bright);
	background-color: color(red500);
	border: 2px solid color(red500);
	border-radius: $border-radius;
	outline: none;
	text-decoration: none;
	cursor: pointer;
	transition: background-color $transition-time $transition-function;

	&.btn--hover,
	&.btn--pressed,
	&:focus,
	&:hover,
	&:active {
		outline: none;
		text-decoration: none;
		color: color(white);
		background-color: color(red600);
		border-color: color(red600);
	}

	&.btn--pressed,
	&:active {
		border: none;
		padding: 9px 10px;
		box-shadow: inset 0 2px 6px 0 rgba(0,0,0,0.25);
	}

	&:focus {
		// outline: 2px dashed color(black);
		// outline-offset: 3px;
		box-shadow: 0 0 0 2px color(red500);
	}

	@include breakpoint(mid) {
		padding: 8px 16px 10px 16px;
		height: 40px;
		line-height: 1.2;

		&.btn--pressed,
		&:active {
			padding: 11px 18px;
		}
	}

	@include breakpoint(max) {
		height: 2.5vw;
		line-height: 1.45vw;
	}
}



//
// Button Secondary
//
.btn--secondary {
	color: color(red500);
	background-color: color(bright);
	transition: background-color $transition-time $transition-function;

	&.btn--hover,
	&.btn--pressed,
	&:focus,
	&:hover,
	&:active {
		background-color: color(red500);
		border-color: color(red500);
	}
}



//
// Button Negative Primary
//
.btn--negative {
	color: color(red500);
	background-color: color(bright);
	border-color: color(bright);
	transition: box-shadow $transition-time $transition-function;

	&.btn--hover,
	&.btn--pressed,
	&:focus,
	&:hover,
	&:active {
		color: color(red600);
		background-color: color(bright);
		border-color: color(bright);
		box-shadow: 0 3px 7px 0 rgba(19,24,33,0.05), 0 0 5px 0 rgba(19,24,33,0.05), 0 1px 2px 0 rgba(19,24,33,0.15), 0 3px 8px 0 rgba(0,0,0,0.05), 0 0 2px 0 rgba(19,24,33,0.05);
	}

	&.btn--pressed,
	&:active {
		box-shadow: inset 0 1px 3px 0 rgba(0,0,0,0.10), inset 0 2px 6px 0 rgba(0,0,0,0.15);
	}
}



//
// Button Negative Secondary
//
.btn--negative.btn--secondary {
	color: color(bright);
	background-color: color(red500);
	transition: background-color $transition-time $transition-function;

	&.btn--hover,
	&.btn--pressed,
	&:focus,
	&:hover,
	&:active {
		color: color(red500);
		background-color: color(bright);
		border-color: color(bright);
	}

	&.btn--pressed,
	&:active {
		color: color(red600);
	}
}



//
// Button + Button
//
.btn ~ .btn {
	margin-left: 24px;

	@include breakpoint(mid) {
		margin-left: 2vw;
	}

	@include breakpoint(max) {
		margin-left: 1.5vw;
	}
}



//
// Link-Button (with arrow)
//
.linkbutton {
	position: relative;
	color: color(red500);

	&::after {
		// outline: 1px solid #ccc;
		content: '';
		display: inline-block;
		width: 24px;
		height: 12px;
		background-size: 6px;
		background-position: left 8px top 2px;
		background-repeat: no-repeat;
		background-color: transparent;
		@include background-svg('<svg width="6" height="10" viewBox="0 0 6 10" xmlns="http://www.w3.org/2000/svg"><path d="M1 1l4 4-4 4" stroke="#EC0016" stroke-width="1.333" fill="none" fill-rule="evenodd" stroke-linecap="round" stroke-linejoin="round"/></svg>');
		transition: all $transition-time $transition-function;

		@include breakpoint(mid) {
			height: 14px;
			background-size: 7px;
		}

		@include breakpoint(max) {
			height: 15px;
			background-size: 8px;
		}
	}

	&.btn--hover,
	&.btn--pressed,
	&:focus,
	&:hover,
	&:active {
		text-decoration: none;
		color: color(red600);

		&::after {
			@include background-svg('<svg width="6" height="10" viewBox="0 0 6 10" xmlns="http://www.w3.org/2000/svg"><path d="M1 1l4 4-4 4" stroke="#C50014" stroke-width="1.333" fill="none" fill-rule="evenodd" stroke-linecap="round" stroke-linejoin="round"/></svg>');
		}
	}

	&.btn--hover,
	&:focus,
	&:hover {

		&::after {
			background-position: left 12px top 2px;
		}
	}
}



//
// Buttons on Grey Background
//
.background-color--coolgray100 {
	.btn--secondary,
	.btn--secondary:not([href]):not([tabindex]) {
		background-color: transparent;

		&.btn--hover,
		&.btn--pressed,
		&:focus,
		&:hover,
		&:active {
			background-color: color(red500);
			border-color: color(red500);
		}
	}
}


//
// Link-Button on Red Background
//
.background-color--red500 {

	.btn {
		&:focus {
			box-shadow: 0 0 0 2px color(white);
		}
	}

	.linkbutton {
		color: color(bright);

		&::after {
			@include background-svg('<svg width="6" height="10" viewBox="0 0 6 10" xmlns="http://www.w3.org/2000/svg"><path d="M1 1l4 4-4 4" stroke="#fff" stroke-width="1.333" fill="none" fill-rule="evenodd" stroke-linecap="round" stroke-linejoin="round"/></svg>');
		}

		&.btn--pressed,
		&:active {
			color: color(red600);

			&::after {
				@include background-svg('<svg width="6" height="10" viewBox="0 0 6 10" xmlns="http://www.w3.org/2000/svg"><path d="M1 1l4 4-4 4" stroke="#C50014" stroke-width="1.333" fill="none" fill-rule="evenodd" stroke-linecap="round" stroke-linejoin="round"/></svg>');
			}
		}

		&:focus {
			box-shadow: 0 0 0 2px color(white);
		}
	}
}



//
// Button with Icon (Livestream red-dot)
//
@keyframes pulse {
	0%   { transform: translate(0, -50%) scale(.8); }
	50%  { transform: translate(0, -50%) scale(1.3);   }
	100% { transform: translate(0, -50%) scale(.8); }
}

.btn--hasicon {
	position: relative;
	padding: 7px 8px 7px 40px;

	@include breakpoint(mid) {
		padding: 8px 16px 10px 40px;

		&.btn--pressed,
		&:active {
			padding: 8px 16px 10px 40px;
		}
	}

	&::before {
		content: '';
		position: absolute;
		left: 15px;
		top: 50%;
		display: block;
		width: 12px;
		height: 12px;
		border-radius: 50%;
		background-color: color(red500);
		transform: translate(0, -50%);
		transform-origin: center center;
		animation: pulse 2s 2s infinite linear;
	}


	&.btn--hover,
	&.btn--pressed,
	&:focus,
	&:hover,
	&:active {
		background-color: color(bright);
		color: color(red500);
		border: 2px solid color(red500);
	}
}
