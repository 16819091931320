/*!
 * die wegmeister gmbh
 * https://www.die-wegmeister.com/
 *
 */
/* prettier-ignore */

@charset 'UTF-8';

// 1. Configuration and helpers
@import
	'abstracts/variables',
	'abstracts/mixins',
	'abstracts/functions',
	'abstracts/pesticide',
	'abstracts/debugging';


// 2. Vendors
@import
	'vendor/bootstrap-reboot',
	'vendor/tiny-slider';


// 3. Base stuff
@import
	'base/fonts',
	'base/base',
	'base/spacer',
	'base/typography',
	'base/helpers',
	'base/color-helpers';


// 4. Layout-related sections
@import
	'layout/footer',
	'layout/header',
	'layout/menu',
	'layout/section';


// 5. Components
@import
	'components/molecules/media-infos',
	'components/molecules/title',
	'components/molecules/buttons',
	'components/molecules/form',
	'components/molecules/icons',
	'components/molecules/iframe',
	'components/molecules/image',
	'components/molecules/lists',
	'components/molecules/video',

	'components/accordion',
	'components/blogpost',
	'components/blogpost--columns',
	'components/blogpost--imagegrid',
	'components/blogpost--media',
	'components/downloadlist',
	'components/downloadtiles',
	'components/event',
	'components/filters',
	'components/intro',
	'components/map',
	'components/relatedtiles',
	'components/carousel',
	'components/newsletterform',
	'components/newsteasers',
	'components/scheduleboard',
	'components/slideshow',
	'components/teaser',
	'components/teaser--image',
	'components/teaser--video',
	'components/textmedia',
	'components/textmediatext',
	'components/timeline',
	'components/booking-calendar',
	'components/cookieplaceholder';



// 6. Page-specific styles
@import
	'pages/boxedlayout';


// 7. Themes
// @import
//	'themes/color-theme';
