.intro-wrapper {
	display: flex;
	flex-wrap: wrap;

	> div {
		width: calc(50% - 2.5vw);

		@include breakpoint(mid) {
			width: calc(33.3% - 2.5vw);
		}

		&:nth-of-type(odd) {
			margin-right: 2.5vw;
		}

		&:nth-of-type(even) {
			margin-left: 2.5vw;

			@include breakpoint(mid) {
				margin-left: 1.25vw;
				margin-right: 1.25vw;
			}
		}

		&:last-of-type {
			transform: translateY(-50%);

			@include breakpoint(mid) {
				transform: translateY(25%);
				margin-left: 2.5vw;
				margin-right: 0;
			}
		}
	}


	// half <p> margins
	img, .linkbutton {
		margin-bottom: 3vw;

		@include breakpoint(min) {
			margin-bottom: 12px;
		}

		@include breakpoint(mid) {
			margin-bottom: 1.5vw;
		}

		@include breakpoint(max) {
			margin-bottom: 1vw;
		}
	}

	img {
		transform: scale(1);
		transition: transform 0.1s ease-in-out;
	}

	img:hover {
		transform: scale(1.02);
		transition: transform 0.1s ease-in-out;
	}
}

.intro-margin-right {
	margin-top: 6vw;

	@include breakpoint(min) {
		margin-top: 24px;
	}

	@include breakpoint(mid) {
		margin-top: 3vw;
	}

	@include breakpoint(max) {
		margin-top: 2vw;
	}
}
