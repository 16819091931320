//*******************************************************************************
// This file contains very basic styles.
//*******************************************************************************

//
// https://css-tricks.com/almanac/properties/s/scroll-behavior/
// https://twitter.com/zachleat/status/1295737380738146304/
//
html {
	scroll-behavior: smooth;

	@media (prefers-reduced-motion: reduce) {
		scroll-behavior: auto;
	}
}



body {
	@include font-family(sansregular);
	font-size: 16px;
	color: color(black);
}



a[href^="tel"] {
	color: color(black) !important;
}


a {
	color: color(red500);

	&:active,
	&:focus,
	&:hover {
		color: color(red600);
		text-decoration: none;
	}

	&:focus {
		outline: none;
		text-decoration: none;
		box-shadow: 0 0 0 2px color(red500);
	}
}


//
// This will hide the focus indicator if the element receives focus via the mouse,
// but it will still show up on keyboard focus.
// Source: https://github.com/WICG/focus-visible
//
.js-focus-visible :focus:not(.focus-visible) {
	outline: none;
	box-shadow: none;
}


.coloredlink {
	color: color(red500);
}
